<template>
  <div>
    <b-card title="Coupon Codes">

      <!-- search input -->
      <div class="custom-search d-flex justify-content-between">
        <b-form-group v-if="this.showAdd">
          <div class="d-flex align-items-center">
            <b-button
                id="toggle-btn"
                v-b-modal.modal-prevent-closing
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="addNew()"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">Add New</span>
            </b-button>
          </div>
        </b-form-group>
        <b-form-group class="justify-content-end">
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
          :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
          styleClass="vgt-table striped condensed no-outline"
      >

        <template
            slot="table-row"
            slot-scope="props"
        >

          <!-- Column: Name -->
          <span v-if="props.column.field === 'status'"
              class="text-nowrap"
          >
            <span @click="updateStatus(props.row)" style="cursor:pointer">
              <span v-if="props.row.status==true"><b-badge pill variant="light-success">Active</b-badge></span>
              <span v-else> <b-badge pill variant="light-danger"> Inactive</b-badge></span>
            </span>
        </span>
          <span v-else-if="props.column.field === 'index'">
             <span>{{props.row.originalIndex+1}}</span>
        </span>
<!--          <span v-else-if="props.column.field === 'start_date'">-->
<!--             <span>{{props.row.start_date.split('T')[0] +" , " + props.row.start_date.split('T')[1].split('+')[0] }}</span>-->
<!--        </span>-->

<!--          <span v-else-if="props.column.field === 'end_date'">-->
<!--             <span>{{props.row.end_date.split('T')[0] +" , " + props.row.end_date.split('T')[1].split('+')[0] }}</span>-->
<!--        </span>-->
          <span v-else-if="props.column.field === 'coupon_value'">
             <span v-if="props.row.coupon_type=='Percentage'">{{props.row.coupon_value}} %</span>
             <span v-else>৳ {{props.row.coupon_value}}</span>
        </span>


          <!-- Column: Status -->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
          <span>
              <b-button @click="edit(props.row)"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary" class="btn-icon rounded-circle mr-1"
                        v-if="showEdit"
              >
                <feather-icon
                    icon="Edit2Icon"
                />
              </b-button>
              <b-button @click="deleteType(props.row)"
                        id="delete-btn"
                        v-b-modal.modal-delete
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary" class="btn-icon rounded-circle"
                        v-if="showDelete"
              >
                  <feather-icon
                      icon="TrashIcon"
                  />
              </b-button>
          </span>
        </span>

          <!-- Column: Common -->
          <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
          id="modal-prevent-closing"
          ref="my-modal"
          centered
          :title="getModalTitle()"
          ok-title="Submit"
          cancel-variant="outline-secondary"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
      >
        <form
            ref="form"
            @submit.stop.prevent="handleSubmit"
        >
          <b-col>
            <b-col>
              <b-form-group
                :state="nameState"
                label="Code"
                label-for="name-input"
                invalid-feedback="  Code is required"
            >

              <b-form-input
                  id="code-input"
                  v-model="modify.coupon_code"
                  :state="nameState"
                  required
              />
            </b-form-group>
            </b-col>
            <b-col class="d-inline-flex">
              <b-col md="6">
                <b-form-group
                  label="Type"
                  label-for="fh-type"
                  :state="nameState"
                  invalid-feedback="  Coupon Type is required"
              >
                <v-select
                    id="fh-type"
                    v-model="modify.coupon_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOp"
                    required

                />
              </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :state="nameState"
                  label="Value"
                  label-for="value-input"
                  invalid-feedback="  Value is required"
              >

                <b-form-input
                    id="value-input"
                    v-model="modify.coupon_value"
                    :state="nameState"
                    required
                />
              </b-form-group>
              </b-col>
            </b-col>
            <b-col>
              <b-form-group
                  label="Once Per User"
                  label-for="fh-puser"
                  :state="nameState"
                  invalid-feedback="  Coupon Once per user is required"
              >
                <v-select
                    id="fh-puser"
                    v-model="modify.once_per_user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perUserOption"
                    required

                />
              </b-form-group>
            </b-col>

            <b-col class="d-inline-flex">
              <b-col>
                <b-form-group
                  :state="nameState"
                  label="Start Date"
                  label-for="date-start"
                  invalid-feedback=" Start Date is required"
              >
                <flat-pickr
                    v-model="modify.start_date"
                    class="form-control"
                    :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d'}"
                    placeholder="Select Start Date and Time"
                />

              </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :state="nameState"
                  label="End Date"
                  label-for="date-end"
                  invalid-feedback=" End Date is required"
              >
                <flat-pickr
                    v-model="modify.end_date"
                    class="form-control"
                    :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d'}"
                    placeholder="Select End Date and Time"
                />

              </b-form-group>
              </b-col>
            </b-col>
            <b-col>
              <b-form-group
                  :state="nameState"
                  label="Usage Limit"
                  label-for="Usage-input"
                  invalid-feedback=" Usage Limit is required"
              >

                <b-form-input
                    id="Usage-input"
                    v-model="modify.usage_limit"
                    placeholder="Use 0 for unlimited"
                    :state="nameState"
                    required
                />
              </b-form-group>
            </b-col>
          </b-col>
        </form>
      </b-modal>
      <b-modal
          id="modal-delete"
          ref="modal-delete"
          centered
          modal-class="modal-danger"
          title="Are you sure?"
      >
        <b-card-text class="border-2">
          <span>If you delete, you won't be able to revert this action!!</span>
        </b-card-text>

        <template #modal-footer>
          <div class="w-100">


            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="float-right"
                @click="confirmDelete()"
            >
              Accept
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-right mr-1"
                @click="$refs['modal-delete'].toggle('#delete-btn')"
            >
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>

import {

  BFormFile,
  BCol,
    BRow,
  BFormTextarea,
  BForm,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BModal,
  BFormDatepicker,
  VBModal,
  BCardText
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import flatPickr from 'vue-flatpickr-component'


export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BRow,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
    BFormFile,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    BFormDatepicker,
    flatPickr,
    vSelect
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {

    ...mapState('coupon', ["coupon", 'couponError', 'couponLoading', "submitError", "submitSuccess", "submitLoading"]),
    // ...mapState('business', ["businesses","businessLoading","businessError"]),
    // ...mapState('productCategory', ["commissionList", "productCategoryError", "productCategoryLoading", "submitError", "submitSuccess", "submitLoading"]),

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {

    coupon(newValue, oldValue) {
      if (newValue) {
        this.rows = this.coupon
      }
    }
  },
  data() {
    return {
      nameState: null,
      addNewMode: false,
      popupActive: true,
      modify: {
        "coupon_code": "",
        "coupon_type": "",
        "coupon_value": null,
        "usage_limit": null,
        "start_date": null,
        "end_date": null,
        "once_per_user": false,
      },
      pageLength: 5,
      dir: false,
      columns: [

        {
          label: '#',
          field: 'index',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center'
        }, {
          label: 'Coupon Code',
          field: 'coupon_code',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Type',
          field: 'coupon_type',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Usage Limit',
          field: 'usage_limit',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Total Used',
          field: 'total_used',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Value',
          field: 'coupon_value',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'Start Date',
          field: 'start_date',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'End Date',
          field: 'end_date',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },

      ],
      typeOp: ['Fixed', 'Percentage'],
      perUserOption: [true, false],
      methodId: null,
      showAdd: true,
      showEdit: true,
      showDelete: true,
      rows: [],
      searchTerm: '',
      product_image1: null,
      product_image_id1: undefined,
    }
  },
  beforeCreate() {

  },
  created() {

    this.$store.dispatch('coupon/fetchCoupon').then(result => {
      this.rows = this.coupon
    })
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    // this.showAdd = permissions.includes("add_couponcodes")
    // this.showEdit = permissions.includes("change_couponcodes")
    // this.showDelete = permissions.includes("delete_couponcodes")
    // if (this.showEdit || this.showDelete) {
      this.columns.push({
        label: 'Action',
        field: 'action',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: false,
      })
    // }
  },
  methods: {
    updateStatus(data) {
      this.$store.dispatch('coupon/toggleCouponStatus', {id: data.id}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      if (this.addNewMode) {
        await this.$store.dispatch('coupon/addCoupon', this.modify).then(result => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: result.code === 400 ? `Error` : `Success`,
              icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
              variant: result.code === 400 ? `danger` : 'success',
              text: result.message,
            },
          })
        })
      } else {
        let payload = {
          payload: this.modify,
          id: this.methodId
        }
        await this.$store.dispatch('coupon/updateCoupon', payload).then(result => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: result.code === 400 ? `Error` : `Success`,
                  icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
                  variant: result.code === 400 ? `danger` : 'success',
                  text: result.message,
                },
              })
            }
        )
      }
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    addNew() {
      this.addNewMode = true
      this.modify.coupon_code = null
      this.modify.coupon_type = null
      this.modify.coupon_value = null
      this.modify.usage_limit = null
      this.modify.start_date = null
      this.modify.end_date = null
      this.modify.once_per_user = false


    },
    edit(data) {
      this.addNewMode = false;
      this.modify.coupon_code = data.coupon_code
      this.modify.coupon_type = data.coupon_type
      this.modify.coupon_value = data.coupon_value
      this.modify.usage_limit = data.usage_limit
      this.modify.start_date = data.start_date
      this.modify.end_date = data.end_date
      this.modify.once_per_user = data.once_per_user
      this.methodId = data.id

      // this.popupActive=true;
    },
    deleteType(data) {
      this.methodId = data.id
    },
    async confirmDelete() {
      await this.$store.dispatch("coupon/deleteCoupon", {id: this.methodId}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
      this.$nextTick(() => {
        this.$refs['modal-delete'].toggle('#delete-btn')
      })
    },
    getModalTitle() {

      return (this.addNewMode ? 'Add New ' : 'Update ') + 'Coupon'
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
